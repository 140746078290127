var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-address"},[_c('Breadcrumb',{attrs:{"data":[
      {
        name:'Cart',
        to:{
          name:'cart'
        }
      },{
        name:'Address',
      },
    ]}}),_c('div',{staticClass:"common-gap"}),_c('div',{staticClass:"bottom-content"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Address","prop":"address"}},[_c('el-input',{attrs:{"type":"textarea","maxlength":"200"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":"Email","prop":"email"}},[_c('el-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }