<!--新增地址-->
<template>
  <div class="page-address">

    <Breadcrumb

      :data="[
        {
          name:'Cart',
          to:{
            name:'cart'
          }
        },{
          name:'Address',
        },
      ]"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">

      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="Address" prop="address">
          <el-input type="textarea" maxlength="200" v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save()">Save</el-button>
        </el-form-item>
      </el-form>

    </div>

  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

import { addContact } from '@/api/user'


export default {
  components: { Breadcrumb },
  data() {

    let validateEmail = (rule, value, callback) => {
      let emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(value)) {
        callback(new Error('Please check email address!'));
      } else {
        callback();
      }

    };

    return {
      form: {
        address: '',
        email: '',
      },

      rules: {
        address: [{
          required: true, message: 'Please fill in the address information', trigger: 'blur'
        }],
        email: [
          { validator: validateEmail, trigger: 'blur' },
          {
            required: true, message: 'Please fill in the contact email', trigger: 'blur'
          }
        ],
      }

    }
  },
  methods: {
    async save() {
      // 返回上一页
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await addContact(this.form)

          this.$message({
            message: 'Add Address Succeed',
            type: 'success'
          });

          this.$router.go(-1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    }
  },
  created() {
    this.form.email = window.localStorage.getItem('email') || ''
  }
}
</script>

<style lang="less" scoped>

.bottom-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--main-color-bg-gray);
  padding: 30px 75px 100px;

  .card-box {
    border-radius: 12px;
    padding: 40px 20px;
    background: #FAFCFF;
    border: 1px solid #F5F5F5;
    box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .btn {
      padding-left: 150px;
      width: 400px;

      /deep/ .el-button {
        width: 150px;
      }
    }

    .label {
      width: 100px;
      text-align: right;
      padding-right: 20px;
    }

    .content {
      width: 300px;

    }
  }
}
</style>